export var handleRotateImage = function handleRotateImage(image, rotation) {
  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext('2d');
  if (rotation % 180 === 0) {
    canvas.width = image.width;
    canvas.height = image.height;
  } else {
    canvas.width = image.height;
    canvas.height = image.width;
  }
  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate(rotation * Math.PI / 180);
  ctx.drawImage(image, -image.width / 2, -image.height / 2);
  return canvas;
};
export var handleSaveRotation = function handleSaveRotation(imageToRotate, rotation) {
  var imageName = imageToRotate.imageName,
    url = imageToRotate.url;
  return new Promise(function (resolve) {
    var image = new Image();
    image.src = url;
    image.onload = function () {
      var canvas = handleRotateImage(image, rotation);
      var rotatedImageUrl = canvas.toDataURL();
      var fileType = imageToRotate.url.type || 'image/jpeg';
      var fileExtension = fileType.split('/')[1];
      canvas.toBlob(function (blob) {
        var rotatedImageFile = new File([blob], "".concat(imageName).concat(rotation > 0 && '-rotated', ".").concat(fileExtension), {
          type: fileType
        });
        resolve({
          rotatedImageFile: rotatedImageFile,
          rotatedImageUrl: rotatedImageUrl
        });
      });
    };
  });
};