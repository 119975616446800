import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React, { useRef, useState } from 'react';
import { arrayOf, func, shape } from 'prop-types';
import Flex from '@magalu/stereo-ui/atoms/Flex';
import Input from '@magalu/stereo-ui/atoms/Input';
import Text from '@magalu/stereo-ui/atoms/Text';
import { Icon } from '@magalu/stereo-ui-icons';
import { usePublisher } from '@magalu/mixer-publisher';
import { ALLOWED_FILE_TYPES, ALLOWED_IMG_EXT, ALLOWED_VIDEO_EXT, MAX_IMG_QTD, MAX_IMG_SIZE, MAX_VIDEO_QTD, MAX_VIDEO_SIZE, msgErrorGeneral, msgErrorImgQtd, msgErrorImgSize, msgErrorVideoQtd, msgErrorVideoSize } from '../../consts';
import { AttachedImage, AttachedVideo, AttachmentGaleryContainer, AttachmentGaleryItem, AttachmentGaleryList, DeleteAttachment, RoundedButton } from './AttachReviewMedia.styles';
import RotateImageModal from './components';
var AttachReviewMedia = function AttachReviewMedia(_ref) {
  var selectedMedia = _ref.selectedMedia,
    setSelectedMedia = _ref.setSelectedMedia,
    structure = _ref.structure;
  var _usePublisher = usePublisher(),
    publish = _usePublisher.publish;
  var _useState = useState({}),
    _useState2 = _slicedToArray(_useState, 2),
    errors = _useState2[0],
    setErrors = _useState2[1];
  var _useState3 = useState(false),
    _useState4 = _slicedToArray(_useState3, 2),
    modalIsOpen = _useState4[0],
    setModalIsOpen = _useState4[1];
  var _useState5 = useState({
      imageName: null,
      url: null
    }),
    _useState6 = _slicedToArray(_useState5, 2),
    imageToRotate = _useState6[0],
    setImageToRotate = _useState6[1];
  var refs = {
    fileInputPhotoRef: useRef(null),
    fileInputVideoRef: useRef(null)
  };
  var shouldDisableAttachment = function shouldDisableAttachment(mediaType) {
    var mediaList = selectedMedia.shownMedia.filter(function (item) {
      return item.type === mediaType;
    });
    var mediaQtd = mediaType === 'image' ? MAX_IMG_QTD : MAX_VIDEO_QTD;
    return mediaList.length >= mediaQtd;
  };
  var updateErrors = function updateErrors(key, error) {
    setErrors(function (prevErrors) {
      var newErrors = _extends({}, prevErrors, error);
      if (!error) {
        delete newErrors[key];
      }
      return newErrors;
    });
  };
  var validateMedia = function validateMedia(id, value) {
    var error = value ? _defineProperty({}, id, value) : undefined;
    updateErrors(id, error);
  };
  var clearErrors = function clearErrors() {
    validateMedia('fileInputPhotoRef', undefined);
    validateMedia('fileInputVideoRef', undefined);
  };
  var verifyMediaFile = function verifyMediaFile(fileType, fileSize, mediaCount) {
    if (fileType === 'image') {
      if (mediaCount > MAX_IMG_QTD) {
        validateMedia('fileInputPhotoRef', msgErrorImgQtd);
      } else if (fileSize > MAX_IMG_SIZE) {
        validateMedia('fileInputPhotoRef', msgErrorImgSize);
      }
    }
    if (fileType === 'video') {
      if (mediaCount > MAX_VIDEO_QTD) {
        validateMedia('fileInputVideoRef', msgErrorVideoQtd);
      } else if (fileSize > MAX_VIDEO_SIZE) {
        validateMedia('fileInputVideoRef', msgErrorVideoSize);
      }
    }
  };
  var verifyMediaSize = function verifyMediaSize(fileType, fileSize) {
    return fileSize <= (fileType === 'image' ? MAX_IMG_SIZE : MAX_VIDEO_SIZE);
  };
  var verifyMediaExtension = function verifyMediaExtension(fileExtension) {
    return ALLOWED_IMG_EXT.includes(fileExtension) || ALLOWED_VIDEO_EXT.includes(fileExtension);
  };
  var verifyFileType = function verifyFileType(attachedFileType, fileType) {
    return attachedFileType === fileType && ALLOWED_FILE_TYPES.includes(fileType);
  };
  var verifyIsValidFile = function verifyIsValidFile(attachedFileType, fileType, fileExtension) {
    return attachedFileType === fileType && (ALLOWED_IMG_EXT.includes(fileExtension) || ALLOWED_VIDEO_EXT.includes(fileExtension));
  };
  var handleMediaType = function handleMediaType(fileType, file) {
    var reader = new FileReader();
    reader.onloadend = function () {
      if (fileType === 'image') {
        setImageToRotate({
          imageName: file.name.split('.')[0],
          url: URL.createObjectURL(file)
        });
        setModalIsOpen(true);
      } else {
        setSelectedMedia(function (prevMedia) {
          return {
            attachedMedia: [].concat(_toConsumableArray(prevMedia.attachedMedia), [file]),
            shownMedia: [].concat(_toConsumableArray(prevMedia.shownMedia), [{
              type: fileType,
              url: reader.result
            }])
          };
        });
      }
    };
    reader.readAsDataURL(file);
  };
  var handleMediaChange = function handleMediaChange(event, attachedFileType) {
    clearErrors();
    var counterImage = selectedMedia.shownMedia.filter(function (elem) {
      return elem.type === 'image';
    }).length;
    var counterVideo = selectedMedia.shownMedia.filter(function (elem) {
      return elem.type === 'video';
    }).length;
    _toConsumableArray(event.target.files).forEach(function (file) {
      var _file$type$split = file.type.split('/'),
        _file$type$split2 = _slicedToArray(_file$type$split, 2),
        fileType = _file$type$split2[0],
        fileExtension = _file$type$split2[1];
      var fileSize = file.size;
      if (fileType === 'image') {
        counterImage += 1;
      }
      if (fileType === 'video') {
        counterVideo += 1;
      }
      var isValidFile = verifyIsValidFile(attachedFileType, fileType, fileExtension);
      if (isValidFile) {
        verifyMediaFile(fileType, fileSize, fileType === 'image' ? counterImage : counterVideo);
        var isValidFileType = verifyFileType(attachedFileType, fileType);
        var isValidFileExtension = verifyMediaExtension(fileExtension);
        var isValidMediaSize = verifyMediaSize(fileType, fileSize);
        var isValidMediaQtd = counterImage <= MAX_IMG_QTD && counterVideo <= MAX_VIDEO_QTD;
        if (isValidFileType && isValidFileExtension && isValidMediaSize && isValidMediaQtd) {
          clearErrors();
          handleMediaType(fileType, file);
        }
      } else {
        validateMedia('fileInputPhotoRef', msgErrorGeneral);
      }
    });
  };
  var renderAttachmentAlertMessage = function renderAttachmentAlertMessage(message) {
    return React.createElement(Flex, {
      mt: 3,
      gap: "8px",
      alignItems: "center"
    }, React.createElement(Icon, {
      name: "InfoCircle",
      width: 20,
      height: 20,
      color: "alert.danger.text"
    }), React.createElement(Text, {
      lineHeight: "fontSize",
      fontWeight: "regular",
      fontSize: [1, 3],
      color: "alert.danger.text"
    }, message));
  };
  var handleButtonMediaClick = function handleButtonMediaClick(event, mediaType) {
    event.preventDefault();
    if (mediaType === 'video') {
      var _refs$fileInputVideoR, _refs$fileInputVideoR2;
      publish('product:reviewform:attachimage:click', {
        structure: structure
      });
      (_refs$fileInputVideoR = refs.fileInputVideoRef) == null ? void 0 : (_refs$fileInputVideoR2 = _refs$fileInputVideoR.current) == null ? void 0 : _refs$fileInputVideoR2.click();
    } else {
      var _refs$fileInputPhotoR, _refs$fileInputPhotoR2;
      publish('product:reviewform:attachimage:click', {
        structure: structure
      });
      (_refs$fileInputPhotoR = refs.fileInputPhotoRef) == null ? void 0 : (_refs$fileInputPhotoR2 = _refs$fileInputPhotoR.current) == null ? void 0 : _refs$fileInputPhotoR2.click();
    }
  };
  var handleRemovePhotoRef = function handleRemovePhotoRef() {
    refs.fileInputPhotoRef.current.value = '';
    refs.fileInputPhotoRef.current.type = 'file';
  };
  var handleRemoveVideoRef = function handleRemoveVideoRef() {
    refs.fileInputVideoRef.current.value = '';
    refs.fileInputVideoRef.current.type = 'file';
  };
  var handleRemoveMedia = function handleRemoveMedia(event, index) {
    event.preventDefault();
    setSelectedMedia(function (prevMedias) {
      return {
        attachedMedia: prevMedias.attachedMedia.filter(function (_, i) {
          return i !== index;
        }),
        shownMedia: prevMedias.shownMedia.filter(function (_, i) {
          return i !== index;
        })
      };
    });
    handleRemovePhotoRef();
    handleRemoveVideoRef();
    clearErrors();
  };
  return React.createElement(React.Fragment, null, React.createElement(Flex, {
    flexDirection: "column",
    mt: 4
  }, imageToRotate && modalIsOpen && React.createElement(RotateImageModal, {
    handleRemovePhotoRef: handleRemovePhotoRef,
    imageToRotate: imageToRotate,
    modalIsOpen: modalIsOpen,
    setModalIsOpen: setModalIsOpen,
    setSelectedMedia: setSelectedMedia
  }), React.createElement(Text, {
    lineHeight: "fontSize",
    fontWeight: "medium",
    fontSize: [3, 5]
  }, "Compartilhe fotos do seu produto"), React.createElement(Text, {
    lineHeight: "fontSize",
    fontWeight: "regular",
    fontSize: [2, 4],
    m: "24px 0"
  }, "Ao compartilhar fotos ou v\xEDdeos voc\xEA ajuda outras pessoas na decis\xE3o de compra."), React.createElement(Text, {
    lineHeight: "fontSize",
    fontWeight: "regular",
    fontSize: [1, 3]
  }, "Voc\xEA pode enviar at\xE9 duas fotos com tamanho m\xE1ximo de 10MB e 1 v\xEDdeo de at\xE9 100MB.")), React.createElement(React.Fragment, null, React.createElement(Input, {
    id: "image-upload",
    type: "file",
    accept: "image/*",
    ref: refs.fileInputPhotoRef,
    style: {
      display: 'none'
    },
    onChange: function onChange(event) {
      return handleMediaChange(event, ALLOWED_FILE_TYPES[0]);
    },
    multiple: true,
    "data-testid": "upload-img",
    capture: "environment"
  }), React.createElement(Input, {
    id: "video-upload",
    type: "file",
    accept: "video/*",
    ref: refs.fileInputVideoRef,
    style: {
      display: 'none'
    },
    onChange: function onChange(event) {
      return handleMediaChange(event, ALLOWED_FILE_TYPES[1]);
    },
    multiple: true,
    "data-testid": "upload-video",
    capture: "environment"
  }), selectedMedia.shownMedia.length > 0 && React.createElement(AttachmentGaleryContainer, null, React.createElement(AttachmentGaleryList, null, selectedMedia.shownMedia.map(function (media, index) {
    return React.createElement(AttachmentGaleryItem, {
      key: "attached-item-".concat(index + 1)
    }, media.type === 'image' ? React.createElement(AttachedImage, {
      id: "photo-uploader",
      src: media.url,
      alt: "selected image ".concat(index + 1)
    }) : React.createElement(AttachedVideo, {
      "data-testid": "video-uploader-".concat(index + 1),
      src: media.url,
      controls: true
    }), React.createElement(DeleteAttachment, {
      "aria-label": "remove-item-".concat(index),
      onClick: function onClick(event) {
        return handleRemoveMedia(event, index);
      }
    }, React.createElement(Icon, {
      name: "Close",
      width: 20,
      height: 20
    })));
  }))), errors.fileInputPhotoRef && renderAttachmentAlertMessage(errors.fileInputPhotoRef), errors.fileInputVideoRef && renderAttachmentAlertMessage(errors.fileInputVideoRef)), React.createElement(Flex, {
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: ['100%'],
    mt: "12px"
  }, React.createElement(RoundedButton, {
    full: true,
    centered: true,
    size: "large",
    uppercase: false,
    m: "16px 0",
    variation: "outline",
    maxWidth: "568px",
    "data-testid": "button-submit-photo",
    htmlFor: "image-upload",
    onClick: function onClick(event) {
      return handleButtonMediaClick(event, 'image');
    },
    disabled: shouldDisableAttachment('image'),
    startIcon: React.createElement(Icon, {
      name: "CategoryCF"
    })
  }, "Enviar foto"), React.createElement(RoundedButton, {
    full: true,
    centered: true,
    size: "large",
    uppercase: false,
    variation: "outline",
    maxWidth: "568px",
    "data-testid": "button-submit-video",
    htmlFor: "video-upload",
    onClick: function onClick(event) {
      handleButtonMediaClick(event, 'video');
    },
    disabled: shouldDisableAttachment('video'),
    startIcon: React.createElement(Icon, {
      name: "CategoryFM"
    })
  }, "Enviar v\xEDdeo")));
};
process.env.NODE_ENV !== "production" ? AttachReviewMedia.propTypes = {
  selectedMedia: shape({
    attachedMedia: arrayOf(shape({})),
    shownMedia: arrayOf(shape({}))
  }).isRequired,
  setSelectedMedia: func.isRequired,
  structure: shape({})
} : void 0;
AttachReviewMedia.defaultProps = {
  structure: {}
};
export default AttachReviewMedia;